import React from 'react'

export default function IfRender({ display, children }) {
    if (display)
        return (
            children
        )
    else
        return <></>
}
