import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from "react-router";
import { HomeIcon, PaintBrushIcon, ExclamationTriangleIcon, UsersIcon, ChatBubbleBottomCenterTextIcon, WrenchScrewdriverIcon, AdjustmentsHorizontalIcon, ShareIcon, LanguageIcon } from '@heroicons/react/24/outline'
import { selectUser } from '../../features/user/userSlice';
import { selectChats, setChats } from '../../features/chats/chatsSlice';
import logo from '../../logo.jpeg';

export default function Sidenav() {
    const dispatch = useDispatch();
    const user = selectUser(useSelector(state => state))
    const chats = selectChats(useSelector(state => state))
    // const chats=[]
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState('/')

    const adminMenus = [
        { name: 'چت‌ها', link: '', icon: <ChatBubbleBottomCenterTextIcon className='w-6 h-6' />, color: 'green', },
        // { name: 'دستیارها', link: 'assistants', icon: <PaintBrushIcon className='w-6 h-6' />, color: 'green', },
        { name: 'کاربران', link: 'users', icon: <UsersIcon className='w-6 h-6' />, color: 'green', },
        { name: 'لاگ', link: 'logs', icon: <ExclamationTriangleIcon className='w-6 h-6' />, color: 'green', },

    ]
    useLayoutEffect(() => {
        (async () => {

        })()
        return () => {

        };
    }, [])
    // const workerMenus = [
    //     { name: 'درخواستها', link: '', icon: <PaintBrushIcon className='w-6 h-6' />, color: 'green', },
    //     { name: 'خانه', link: '', icon: <HomeIcon className='w-6 h-6' />, color: 'green', },

    // ]
    const workerMenus = [
        {
            name: 'زبان‌ها',
            link: '/',
            icon: <LanguageIcon className='w-6 h-6' />,
        },
        {
            name: 'رسانه‌های اجتماعی',
            link: '/socials',
            icon: <ShareIcon className='w-6 h-6' />,
        },
    ]
    useEffect(() => {
        const url = location.pathname.split('/')
        setCurrentPage(location.pathname)
        return () => {

        };
    }, [location.pathname]);

    return (
        <div className='hidden md:flex flex-col h-[85vh] sm:h-screen overflow-x-hidden overflow-y-auto gap-3 '>
            <div className='bg-gradient-to-r from-primary-full to-secondary-full pb-[1px]  mb-9 aspect-video'>
                <div className='flex items-center gap-2 flex-col  justify-center bg-bg w-full h-full'>
                    <img src={logo} className='w-40' />
                    {/* <label className='text-primary-medium text-xl'>پنل المصباح</label> */}
                </div>
            </div>

            <ul className='flex flex-col gap-3  px-6 '>
                {(user?.is_admin ? adminMenus : workerMenus).map((item, i) => <li key={i} className='text-white text-right'>
                    <Link to={item.link} className={`border-r-2 flex gap-3 transition-all items-center justify-start  p-3 rounded-[3rem] ${currentPage === item.link ? 'bg-[#ffffff0f] border-primary-full border-r-4 text-[#FFF]' : 'border-[#ffffff56] hover:bg-[#ffffff0a] text-[#858585]'}`}>
                        <i className={`${(currentPage === item.link) ? `text-primary-medium` : ``}`}>{item.icon}</i>
                        <span className='truncate'>
                            {item.name}
                        </span>
                    </Link>
                </li>)}
            </ul>
        </div>
    )
}
