import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { applicationLogo, applicationTitle } from './api/utility';

const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = applicationTitle
let link = document.querySelector("link[rel~='icon']");
if (!link) {
  link = document.createElement('link');
  link.rel = 'icon';
  document.getElementsByTagName('head')[0].appendChild(link);
}
link.href = applicationLogo;

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <div className='bg-bg fixed z-0 w-screen h-screen overflow-auto'>
        <div className='w-[17.125rem] h-[17.125rem] md:w-[24rem] md:h-[24rem] shrink-0 blur-[200px] rounded-[274px] bg-primary-full fixed md:top-[12rem] md:left-[calc(50%-24rem)] top-0 -left-4 -z-10'></div>
        <div className='w-[17.125rem] h-[17.125rem] md:w-[24rem] md:h-[24rem] shrink-0 blur-[200px] rounded-[274px] bg-secondary-full fixed md:top-[24rem] md:right-[calc(50%-24rem)] top-[17rem] -right-4 -z-10'></div>
        <App />
        
      </div>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
