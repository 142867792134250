import React, { useEffect, useLayoutEffect, useState } from 'react'
import Modal from '../../components/common/Modal';
import { PlusCircleIcon, CheckCircleIcon, XCircleIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import toast from 'react-hot-toast';
import IfRender from '../../components/common/IfRender';
import Loading from '../../components/common/Loading';
import Pagination from '../../components/common/Pagination';
import { createLanguage, deleteLanguage, getLanguage, getLanguages, getSocial, getSocials, setLanguageSocials, updateLanguage, } from '../../api';
import { baseURL } from '../../api/utility';

export default function Home() {
    const [items, setItems] = useState([])
    const [socials, setSocials] = useState([])

    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [showSocialModal, setShowSocialModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [files, setFiles] = useState()
    const [name, setName] = useState("")
    const [languageSocial, setLanguageSocial] = useState([])

    useLayoutEffect(() => {

        (async () => {
            const { data, error } = await getSocials()
            if (data) {
                setSocials(data.data)
            }
        })()
        return () => {

        };
    }, [])
    useEffect(() => {

        if (selected)
            setName(selected.name)
        else {
            setName("")
            setFiles(null)
            setSelected(null)
        }
        return () => {

        }
    }, [selected])

    const handler = async () => {
        setIsLoading(true)
        const { data, error } = await getLanguages()

        if (data) {
            setItems(data.data)
        }
        else {
            console.error(error);
        }
        setIsLoading(false)
    }


    useEffect(() => {

        if (!showModal) {
            handler()
            setSelected(null)
            setName("")
            setFiles(null)
        }
        else {

        }

        return () => {

        }
    }, [showModal])

    const deleteHandler = async id => {
        if (window.confirm('آیا از حذف این رسانه مطمئنید؟')) {
            const { data, error } = await deleteLanguage(id)
            if (data) {
                toast.success('رسانه مورد نظر با موفقت حذف شد')
                handler()
            }
            else {
                toast.error('خطایی در حذف رسانه بوجود آمد')
            }
        }
    }
    const languageHandler = async (e) => {
        e?.preventDefault();

        if (selected?.id) {
            const { data, error } = await updateLanguage(selected.id, { name: name, file: files })
            if (data) {
                setShowModal(false)
                handler()
                toast.success(data.message)
                setFiles(null)
            }
        }
        else {
            const { data, error } = await createLanguage({ name: name, file: files })
            if (data) {
                setShowModal(false)
                toast.success(data.message)
                handler()
                setFiles(null)
            }
        }


    }
    const socialHandler = async () => {

        const { data, error } = await setLanguageSocials(selected.id, languageSocial);
        if (data) {
            setShowSocialModal(false)
            toast.success(data.message)
        }
        else
            toast.error(error?.message)
    }
    return (
        <>
            <div className="container overflow-x-auto">
                <h1 className=' text-white pb-4 mb-2 h-9 flex items-center justify-start rounded-md'>زبان‌ها</h1>
                <section className='bg-[#cccccc3b] p-5 rounded-md'>
                    <div className='w-full flex items-start mb-4  pb-5 gap-2'>
                        <button onClick={e => setShowModal(true)} className='text-sm text-white bg-btn rounded-md w-36 p-2 flex flex-row gap-2'>
                            <PlusCircleIcon className='w-5' />
                            زبان جدید
                        </button>

                    </div>
                    <IfRender display={isLoading}>
                        <Loading />
                    </IfRender>
                    <IfRender display={!isLoading}>
                        <div className='grid grid-cols-3 gap-4'>
                            {
                                items.map((item, i) => (<div key={i} className='bg-[#ffffff2f] rounded-md text-right h-full flex flex-col justify-between'>
                                    <div className='p-10 rounded-md text-right h-full flex flex-col justify-between'>
                                        {/* <h4 className='text-white text-lg'>{item.product_name}</h4> */}
                                        <span className='text-[#fffffff2] text-sm line-clamp-3 mx-auto w-full aspect-square justify-center flex'>
                                            <img src={baseURL + '/' + item.image} className='' />
                                        </span>
                                        <span className='text-[#fffffff2] text-md line-clamp-3 mx-auto w-full text-center'>
                                            <hr className='my-7 border-[#ffffff2f] w-full' />
                                            {item.name}
                                        </span>
                                        <div className='flex w-full justify-between items-center flex-col'>
                                            <hr className='my-7 border-[#ffffff2f] w-full' />
                                            <div className='flex w-full justify-between items-center'>
                                                <button
                                                    onClick={() => {
                                                        setSelected(item)
                                                        setShowModal(true)
                                                    }}
                                                    className={`flex text-sm gap-2  text-white p-2 rounded-md bg-secondary-medium`} >
                                                    ویرایش
                                                </button>
                                                <button
                                                    onClick={async () => {
                                                        const { data, error } = await getLanguage(item.id)
                                                        if (data) {
                                                            setSelected(item)
                                                            setLanguageSocial(data.data.socials.map(x => ({ socialId: x.id, address: x.address })))
                                                        }
                                                        setShowSocialModal(true)
                                                    }}
                                                    className={`flex text-sm gap-2  text-white p-2 rounded-md bg-secondary-medium`} >
                                                    رسانه‌ها
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        deleteHandler(item.id)
                                                    }}
                                                    className={`flex text-sm gap-2  text-white p-2 rounded-md bg-danger`} >
                                                    حذف
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>))
                            }
                        </div>
                        <div className='my-4 mx-auto w-full flex justify-center items-center'>
                            <Pagination pages={pages} currentPage={currentPage} onChange={e => setCurrentPage(e)} />
                        </div>
                    </IfRender>

                </section>
            </div>
            <Modal title={selected?.id ? 'ویراش زبان' : 'افزودن زبان'} showModal={showModal} setShowModal={setShowModal} className={'w-[1000px] min-h-[500px]'}>
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-col items-start w-full mb-3'>
                        <label className=''>نام</label>
                        <input className='w-full bg-[#ffffff44] h-12 rounded-md placeholder:text-[#ffffff88] text-[#ffffffee]' value={name} onChange={e => setName(e.target.value)} />
                    </div>
                    <input className='text-white' type='file'
                        onChange={e => {
                            if (e.target.files.length > 0)
                                setFiles(e.target.files[0])
                        }} />
                    <div className='grid grid-cols-4 gap-3'>
                        {files &&
                            <div className='col-span-1 relative'>
                                <button className='absolute top-2 left-2 w-8' onClick={() => setFiles(null)}>
                                    <XCircleIcon className=' shadow-md rounded-full' />
                                </button>
                                {files && <img className='w-full' src={URL.createObjectURL(files)} />}
                            </div>
                        }
                    </div>
                    <button className='bg-btn p-3 rounded-md' onClick={languageHandler}>ذخیره</button>
                </div>
            </Modal>
            <Modal title={`رسانه‌های زبان ${selected?.name}`} showModal={showSocialModal} setShowModal={setShowSocialModal} className={'w-[1000px] min-h-[500px]'}>
                <div className='grid grid-cols-6 gap-3 justify-between items-center'>
                    {socials?.map((item, i) => {

                        const found = languageSocial?.find(x => x.socialId === item.id)

                        return <React.Fragment>
                            <img src={baseURL + '/' + item.image} className=' h-10 col-span-1' />
                            <label className='w-full col-span-2'> {item.name}</label>
                            <input className='w-full bg-[#ffffff44] h-12 rounded-md placeholder:text-[#ffffff88] text-[#ffffffee]  col-span-3'
                                value={found?.address}
                                onChange={e => {
                                    if (found) {
                                        setLanguageSocial(pre => [...pre.filter(x => x.socialId !== item.id),
                                        {
                                            address: e.target.value,
                                            socialId: item.id

                                        }])
                                    }
                                    else {

                                        setLanguageSocial(pre => [...pre,
                                        {
                                            address: e.target.value,
                                            socialId: item.id

                                        }])
                                    }
                                }}
                            />
                        </React.Fragment>
                    })}
                    <button className='bg-btn p-3 rounded-md col-span-3 outline-offset-2' onClick={socialHandler}>ذخیره</button>
                </div>
            </Modal>

        </>
    );
}
