import axios from "axios";
import { baseURL, convertBase64, instance } from "./utility";
import { useSearchParams } from "react-router-dom";

export const callApi = async (config) => {

    try {
        const { data, status } = await instance.request(config)
        return { data, status }
    }
    catch (error) {
        return { error, status: error.status }
    }
}
const me = async () => {
    let config = {
        method: 'get',
        url: '/api/User/get',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return callApi(config)
}

const login = async ({ username, password }) => {
    let data = {
        username,
        password
    };

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: baseURL + '/api/User/login',
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };

    try {
        const response = await axios(config)
        if (response.data) {
            localStorage.setItem('access_token', response.data.data.token)
            // localStorage.setItem('refresh_token', response.data.refresh)
        }
        return response
    }
    catch (error) {
        return { error }
    }


}

/****************************************************************************/

const getLanguages = () => {
    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        url: `/api/language`,
        headers: {
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const getLanguage = (id) => {
    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        url: `/api/language/${id}`,
        headers: {
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const deleteLanguage = (id) => {
    let config = {
        method: 'DELETE',
        maxBodyLength: Infinity,
        url: `/api/language/${id}`,
        headers: {
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const createLanguage = (data) => {
    const formData = new FormData()
    formData.append('Name', data.name)
    formData.append('File', data.file)

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `/api/language`,
        headers: {
            'Accept': 'multipart/form-data'
        },
        data: formData,
    };

    return callApi(config)
}
const updateLanguage = (id, data) => {
    const formData = new FormData()
    formData.append('Name', data.name)
    formData.append('File', data.file)

    let config = {
        method: 'PUT',
        maxBodyLength: Infinity,
        url: `/api/language/${id}`,
        headers: {
            'Accept': 'multipart/form-data'
        },
        data: formData,
    };

    return callApi(config)
}

const setLanguageSocials = (id, data) => {
    console.log(data);
    let config = {
        method: 'PUT',
        maxBodyLength: Infinity,
        url: `/api/LanguageSocial/${id}`,
        headers: {
            'Accept': 'application/json'
        },
        data: { items: data },
    };

    return callApi(config)
}


const getSocials = () => {
    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        url: `/api/social`,
        headers: {
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const getSocial = (id) => {
    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        url: `/api/social/${id}`,
        headers: {
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const deleteSocial = (id) => {
    let config = {
        method: 'DELETE',
        maxBodyLength: Infinity,
        url: `/api/social/${id}`,
        headers: {
            'Accept': 'application/json'
        },
    };

    return callApi(config)
}

const createSocial = (data) => {
    const formData = new FormData()
    formData.append('Name', data.name)
    formData.append('Image', data.file)

    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `/api/social`,
        headers: {
            'Accept': 'multipart/form-data'
        },
        data: formData
    };

    return callApi(config)
}
const updateSocial = (id, data) => {
    const formData = new FormData()
    formData.append('Name', data.name)
    formData.append('Image', data.file)

    let config = {
        method: 'PUT',
        maxBodyLength: Infinity,
        url: `/api/social/${id}`,
        headers: {
            'Accept': 'multipart/form-data'
        },
        data: formData
    };

    return callApi(config)
}






export {
    me,
    login,

    getLanguages,
    getLanguage,
    createLanguage,
    deleteLanguage,
    updateLanguage,
    setLanguageSocials,

    getSocials,
    getSocial,
    createSocial,
    deleteSocial,
    updateSocial,
}