import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { setLogin } from '../../features/login/loginSlice';
import { selectUser, setUser } from '../../features/user/userSlice';
import { UserIcon, ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline'
import Gravatar from 'react-gravatar';

export default function Topnav() {
    const user = selectUser(useSelector(state => state))

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = () => {
        localStorage.clear()
        dispatch(setLogin(false))
        dispatch(setUser(null))
        navigate('/')
    }
    return (
        <nav className='col-span-full md:col-span-1  px-4 sticky top-0 flex justify-between text-white'>
            <Link to='/profile' className='flex flex-row items-center gap-4 text-sm'>
                <Gravatar email={user?.email} className='rounded-full' />
                <span className='uppercase'>{user?.full_name}</span>
            </Link>
            <span className='hidden md:flex gap-3 items-center '>
                سامانه مدیریت رسانه‌های اجتماعی المصباح
            </span>
            <button className='flex flex-row items-center gap-2 text-sm rounded-full bg-[#ffffff99] aspect-square w-14 flex items-center justify-center' onClick={logout}>
                <ArrowLeftStartOnRectangleIcon className='w-5' />
            </button>
        </nav>
    )
}
