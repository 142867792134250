import { useEffect, useState } from 'react';
import './App.css';
import { selectLogin, setLogin } from './features/login/loginSlice';
import { me } from './api';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { selectUser, setUser } from './features/user/userSlice';
import Login from './pages/Login';
import Loading from './components/common/Loading';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidenav from './components/Sidenav';
import { Toaster } from 'react-hot-toast';
import Home from './pages/Home';
import Topnav from './components/Topnav';
import Social from './pages/Social';





function App() {
  const [isLoading, setIsLoading] = useState(true);

  const login = selectLogin(useSelector(state => state))
  const user = selectUser(useSelector(state => state))
  const dispatch = useDispatch();


  const callAccount = async e => {
    setIsLoading(true)
    try {
      const { data } = await me()

      if (data) {
        dispatch(setLogin(true))
        // dispatch(setUser({ ...data, is_staff: false })) // Edit for check rules
        dispatch(setUser(data))
      }
      else {
        dispatch(setLogin(false))
      }
      setIsLoading(false)
    }
    catch {
      dispatch(setLogin(false))
      dispatch(setUser(null))
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      callAccount()
    }
    else {
      dispatch(setLogin(false))
      setIsLoading(false)
    }


    return () => {

    }
  }, [login])

  if (isLoading)
    return <section className="" >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <Loading />
      </div>
    </section>
  else if (login)
    return (
      <>
        <Toaster containerStyle={{ position: 'fixed' }} />
        <div className="App font-iransans mx-auto grid grid-cols-1 md:grid-cols-5 grid-rows-auto">
          <BrowserRouter>
            <header className='col-span-full border-b border-b-[#cccccc2e]  p-4'>
              <Topnav />
            </header>
            <aside className='col-span-full border-l border-l-[#cccccc2e] md:col-span-1'>
              <Sidenav />
            </aside>
            <main className='col-span-full md:col-span-4 px-2 md:px-48 py-2 md:py-10'>

              <Routes>
                <Route path='socials' element={<Social />} />
                <Route path='*' element={<Home />} />
              </Routes>
            </main>
          </BrowserRouter>
        </div>
      </>

    );
  else
    return <BrowserRouter>
      <Toaster containerStyle={{ position: 'fixed' }} />
      <Routes>
        <Route path="/*" element={<Login />} />
      </Routes>
    </BrowserRouter>
}

export default App;
