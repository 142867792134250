import React, { useEffect, useLayoutEffect, useState } from 'react'
import Modal from '../../components/common/Modal';
import { PlusCircleIcon, CheckCircleIcon, XCircleIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import toast from 'react-hot-toast';
import IfRender from '../../components/common/IfRender';
import Loading from '../../components/common/Loading';
import Pagination from '../../components/common/Pagination';
import { createSocial, deleteSocial, getSocials, updateSocial, } from '../../api';
import { baseURL } from '../../api/utility';

export default function Social() {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [selected, setSelected] = useState(null)

    const [files, setFiles] = useState(null)
    const [name, setName] = useState("")

    const handler = async () => {
        setIsLoading(true)
        const { data, error } = await getSocials()

        if (data) {
            setItems(data.data)
        }
        else {
            console.error(error);
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (selected) {
            setName(selected.name)
        }

        return () => {

        }
    }, [selected])

    useEffect(() => {

        if (!showModal)
            handler()
        if (files)
            URL.revokeObjectURL(files)
        return () => {

        }
    }, [currentPage, showModal])
    const deleteHandler = async id => {
        if (window.confirm('آیا از حذف این رسانه مطمئنید؟')) {
            const { data, error } = await deleteSocial(id)
            if (data) {
                toast.success('رسانه مورد نظر با موفقت حذف شد')
                handler()
            }
            else {
                toast.error('خطایی در حذف رسانه بوجود آمد')
            }
        }
    }
    const socialHandler = async (e) => {
        e?.preventDefault();

        if (selected?.id) {
            const { data, error } = await updateSocial(selected.id, { name: name, file: files })
            if (data) {
                setShowModal(false)
                toast.success(data.message)
                handler()
                setFiles(null)
            }
        }
        else {
            const { data, error } = await createSocial({ name: name, file: files })
            if (data) {
                setShowModal(false)
                toast.success(data.message)
                handler()
                setFiles(null)
            }
        }


    }

    return (
        <>
            <div className="container overflow-x-auto">
                <h1 className=' text-white pb-4 mb-2 h-9 flex items-center justify-start rounded-md'>رسانه‌های اجتماعی</h1>
                <section className='bg-[#cccccc3b] p-5 rounded-md'>
                    <div className='w-full flex items-start mb-4  pb-5 gap-2'>
                        <button onClick={e => setShowModal(true)} className='text-sm text-white bg-btn rounded-md w-36 p-2 flex flex-row gap-2'>
                            <PlusCircleIcon className='w-5' />
                            رسانه جدید
                        </button>

                    </div>
                    <IfRender display={isLoading}>
                        <Loading />
                    </IfRender>
                    <IfRender display={!isLoading}>
                        <div className='grid grid-cols-3 gap-4'>
                            {
                                items.map((item, i) => (<div key={i} className='bg-[#ffffff2f] rounded-md text-right h-full flex flex-col justify-between'>
                                    <div className='p-10 rounded-md text-right h-full flex flex-col justify-between'>
                                        {/* <h4 className='text-white text-lg'>{item.product_name}</h4> */}
                                        <span className='text-[#fffffff2] text-sm line-clamp-3 mx-auto w-full aspect-square justify-center flex'>
                                            <img src={baseURL + '/' + item.image} className='' />
                                        </span>
                                        <span className='text-[#fffffff2] text-md line-clamp-3 mx-auto w-full text-center'>
                                            <hr className='my-7 border-[#ffffff2f] w-full' />
                                            {item.name}
                                        </span>
                                        <div className='flex w-full justify-between items-center flex-col'>
                                            <hr className='my-7 border-[#ffffff2f] w-full' />
                                            <div className='flex w-full justify-between items-center'>
                                                <button
                                                    onClick={() => {
                                                        setSelected(item)
                                                        setShowModal(true)
                                                    }}
                                                    className={`flex text-sm gap-2  w-20 justify-center text-white p-2 rounded-md bg-secondary-medium`} >
                                                    ویرایش
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        deleteHandler(item.id)
                                                    }}
                                                    className={`flex text-sm gap-2 w-20 justify-center text-white p-2 rounded-md bg-danger`} >
                                                    حذف
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>))
                            }
                        </div>
                        <div className='my-4 mx-auto w-full flex justify-center items-center'>
                            <Pagination pages={pages} currentPage={currentPage} onChange={e => setCurrentPage(e)} />
                        </div>
                    </IfRender>

                </section>
            </div>
            <Modal title={selected?.id ? 'ویرایش رسانه' : 'افزودن رسانه'} showModal={showModal} setShowModal={setShowModal} className={'w-[1000px] min-h-[500px]'}>
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-col items-start w-full mb-3'>
                        <label className=''>نام</label>
                        <input className='w-full bg-[#ffffff44] h-12 rounded-md placeholder:text-[#ffffff88] text-[#ffffffee]' value={name} onChange={e => setName(e.target.value)} />
                    </div>
                    <input className='text-white' type='file'
                        onChange={e => {
                            if (e.target.files.length > 0)
                                setFiles(e.target.files[0])
                        }} />
                    <div className='grid grid-cols-4 gap-3'>
                        {files &&
                            <div className='col-span-1 relative'>
                                <button className='absolute top-2 left-2 w-8' onClick={() => setFiles(null)}>
                                    <XCircleIcon className=' shadow-md rounded-full' />
                                </button>
                                {files && <img className='w-full' src={URL.createObjectURL(files)} />}
                            </div>
                        }
                    </div>
                    <button className='bg-btn p-3 rounded-md' onClick={socialHandler}>ذخیره</button>
                </div>
            </Modal>

        </>
    );
}
