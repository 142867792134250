import axios from "axios";


export const BASE_URL = process.env.REACT_APP_BACKEND_ADDRESS
export const applicationTitle = process.env.REACT_APP_TITLE;
export const applicationName = process.env.REACT_APP_NAME;
export const applicationDescription = process.env.REACT_APP_DESCRIPTION;
export const applicationLogo = process.env.REACT_APP_LOGO
export const applicationOwner = process.env.REACT_APP_OWNER
export const applicationOwnerAddress = process.env.REACT_APP_ADDRESS_TEXT
export const applicationOwnerAddressTitle = process.env.REACT_APP_ADDRESS_TITLE
export const applicationOwnerAbout = process.env.REACT_APP_ABOUT
export const applicationOwnerMap = process.env.REACT_APP_ADDRESS_MAP

export const baseURL = BASE_URL || "https://etka.back.askme.avir.co.com";
export const instance = axios.create({
    baseURL
})

// Add Request Interceptor
instance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return config;
});

const refreshTokenEndpoint = baseURL + '/refresh';

// Function to refresh the access token using the refresh token
async function refreshAccessToken() {
    try {
        const response = await axios.post(refreshTokenEndpoint, {
            refresh: localStorage.getItem('refresh_token')
        });
        await localStorage.setItem('access_token', response.data.access);
    } catch (err) {
        console.error(err);
        localStorage.clear();
        window.location.reload();
        throw err;
    }
}

// Axios interceptor to handle expired tokens
instance.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.reload()
        }
        // if (error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true;
        //     return refreshAccessToken().then(() => {
        //         originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
        //         return instance(originalRequest);
        //     });
        // }

        return Promise.reject(error);
    }
);

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}
