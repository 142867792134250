import React from 'react'

export default function Pagination({ pages, currentPage, onChange }) {

    let jsxElements = [];

    for (let i = 1; i <= pages; i++) {
        jsxElements.push(<span onClick={e => onChange(i)} key={i}
            className={`flex flex-col items-center bg-[#ffffff22] p-2 aspect-square rounded-md w-12 text-white ${(currentPage === i ) ? 'after:bg-blue-400 after:w-1 after:h-1 after:block after:mx-auto after:rounded-full cursor-not-allowed' : ' cursor-pointer'}`}>
            {i}
        </span>);
    }

    return <div className='flex flex-row mx-auto gap-2 py-2'>
        {jsxElements}
    </div >

}



