import React, { useEffect, useState } from 'react'
import { login } from '../../api';
import { selectLogin, setLogin } from '../../features/login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/common/Loading';
import toast from 'react-hot-toast';
import { applicationLogo, applicationOwner, applicationTitle } from '../../api/utility';
// import inputField from '../../assets/images/input.svg';

export default function Login() {
    const dispatch = useDispatch()

    const [username, setUsername] = useState("smok");//avir
    const [password, setPassword] = useState("123");//ADAK*avir
    const [isLoading, setIsLoading] = useState(false);

    const handler = async e => {
        e.preventDefault()

        setIsLoading(true)
        const { data, error } = await login({ username, password })

        if (data) {
            await localStorage.setItem('access_token', data.data.token)
            // await localStorage.setItem('refresh_token', data.refresh)
            toast.success('شما با موفقیت وارد شدید!')
            setTimeout(() => {

                dispatch(setLogin(true))
            }, 1000);
        }
        else {
            if (error?.response?.status === 401)
                toast.error('نام کاربری یا رمز عبور اشتباه است')
        }
        setIsLoading(false)

    }

    return (
        <form onSubmit={handler} className="h-screen font-iransans" >
            <div className="flex flex-col items-center justify-between px-6 py-8 mx-auto h-full relative">
                <div className=' sm:relative top-8 flex justify-center flex-col items-center'>


                </div>
                <div className="w-full flex items-center">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8 flex flex-col items-center w-full">
                        <h2 className="font-bold leading-tight tracking-tight text-[#D9D9D9] text-sm">
                            برای ورود اطلاعات خود را وارد کنید
                        </h2>
                        <div className="w-[22rem] h-[3.5rem] bg-no-repeat bg-center bg-input-svg relative block" style={{ marginTop: '2.6rem' }} >
                            {/* Your content here */}
                            <label className='absolute left-[42%] -top-3 text-white text-sm'>نام کاربری</label>
                            <input autoComplete={'false'} className='text-center text-white bg-transparent w-full h-full border-0' type='text' value={username} onChange={e => setUsername(e.target.value)} />
                        </div>

                        <div className="w-[22rem] h-[3.5rem] bg-no-repeat bg-center bg-input-svg relative block" style={{ marginTop: '2.6rem' }}>
                            {/* Your content here */}
                            <label className='absolute left-[44%] -top-3 text-white text-sm'>رمز عبور</label>
                            <input autoComplete={'false'} className='text-center text-white bg-transparent w-full h-full border-0' type="password" name="password"
                                onChange={e => setPassword(e.target.value)} value={password} />
                        </div>


                        {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Don’t have an account yet? <a href="#" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                            </p> */}

                    </div>
                </div>
                <button disabled={isLoading} type="submit"
                    className="rounded-[6.25rem] bg-btn flex w-[22rem] text-white py-4 px-0 justify-center items-center md:mb-52">
                    {isLoading ? <Loading className={'h-6'} /> : 'ورود'}
                </button>

            </div>
        </form>
    )
}
